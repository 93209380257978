import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/PaymentConfirmation.css';
import useDocumentTitle from '../UseDocumentTitle';
import { FaCheckCircle } from 'react-icons/fa';

const PaymentConfirmation = () => {
    useDocumentTitle('Payment Confirmed - StudyBuddy');
    const navigate = useNavigate();

    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            navigate('/chats/new');
        }, 5000); // Redirect after 5 seconds

        return () => clearTimeout(redirectTimer);
    }, [navigate]);

    return (
        <div className="payment-confirmation">
            <div className="confirmation-content">
                <FaCheckCircle className="confirmation-icon" />
                <h1>Payment Confirmed!</h1>
                <p>Thank you for upgrading your StudyBuddy subscription.</p>
                <p>You will be redirected to start your learning journey in a few seconds.</p>
                <div className="loader"></div>
            </div>
        </div>
    );
};

export default PaymentConfirmation;