import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Features.css';
import useDocumentTitle from '../UseDocumentTitle';
import { useAuth } from '../hooks/useAuth';
import { createCheckoutSession, handleStripePortalRedirect } from '../services/api';

const StudyBuddyFeatures = () => {
  
  const { user, isAuthenticated } = useAuth();
  const subscription = user?.subscription;
  const navigate = useNavigate();

  const handleSubscription = async (tier, interval = 'monthly', currency = 'usd') => {
    if (!isAuthenticated) {
      navigate('/signup', { state: { selectedTier: tier, interval, currency } });
      return;
    }

    try {
      if(subscription && subscription.tier !== "free"){
        handleStripePortalRedirect();
        return;
      }
      const response = await createCheckoutSession(tier, interval, currency);
      if (response.url) {
        window.location.href = response.url;
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const renderButton = (tier) => {
    if (!user) {
      return (
        <button 
          onClick={() => handleSubscription(tier.toLowerCase(), 'monthly', 'usd')} 
          className="cta-button"
        >
          {tier.toLowerCase() === 'free' ? 'Get Started' : `Choose ${tier}`}
        </button>
      );
    }

    if (subscription && subscription.tier !== 'free') {
      if (subscription.tier.toLowerCase() === tier.toLowerCase()) {
        return (
          <button onClick={handleStripePortalRedirect} className="cta-button">
            Manage Subscription
          </button>
        );
      } else {
        return (
          <button 
            className="cta-button" 
            style={{visibility: 'hidden'}} 
            disabled
          >
            Invisible
          </button>
        );
      }
    }

    // For free users
    return (
      <button 
        onClick={() => handleSubscription(tier.toLowerCase(), 'monthly', 'usd')} 
        className="cta-button"
      >
        {tier.toLowerCase() === 'free' ? 'Current Plan' : `Choose ${tier}`}
      </button>
    );
  };

  useDocumentTitle('StudyBuddy - Features');
  return (
    <div className="features-container">
      <header className="features-header">
        <h1>Unlock Your Learning Potential with StudyBuddy</h1>
        <p>Powered by advanced AI, tailored to your unique journey</p>
      </header>

      <section className="ai-core">
        <h2>Cutting-Edge AI at Your Fingertips</h2>
        <p>
          StudyBuddy harnesses the power of state-of-the-art language models, fine-tuned to excel in educational contexts. 
          Built on the foundations laid by OpenAI and Anthropic, our AI goes beyond generic responses, offering insights 
          that are tailored to your academic level and learning style.
        </p>
        <div className="ai-showcase">
          <div className="ai-example">
            <h3>Deep Conceptual Understanding</h3>
            <p>"Explain quantum entanglement as if you're talking to a physics undergraduate."</p>
          </div>
          <div className="ai-example">
            <h3>Cross-Disciplinary Connections</h3>
            <p>"How does the concept of scarcity in economics relate to biodiversity in ecology?"</p>
          </div>
        </div>
      </section>

      <section className="personalization">
        <h2>Your Personal Academic Companion</h2>
        <p>
          StudyBuddy doesn't just answer questions; it gets to know you. Through sophisticated algorithms, 
          it adapts to your learning pace, remembers your strengths and weaknesses, and tailors its responses 
          to push your understanding further with each interaction.
        </p>
        <ul className="personalization-features">
          <li>Adaptive difficulty scaling</li>
          <li>Contextual memory across sessions</li>
          <li>Customized examples based on your interests</li>
          <li>Proactive suggestions for deeper exploration</li>
        </ul>
      </section>

      <section className="tier-benefits">
        <h2>Elevate Your Experience with Premium Tiers</h2>
        <p>
          While our core AI is powerful across all tiers, upgrading unlocks features designed to 
          accelerate your academic growth and provide a more immersive learning experience.
        </p>
        <div className="tier-comparison">
        <div className="tier">
            <h3>Free</h3>
            <ul>
              <li>Access to core AI capabilities</li>
              <li>Daily interaction limits of 3 chats</li>
            </ul>
            {renderButton('free')}
          </div>
          <div className="tier">
            <h3>Premium</h3>
            <ul>
              <li>Access to core AI capabilities</li>
              <li>Increased interaction limits of 10 chats</li>
              <li>General study planning tools</li>
            </ul>
            {renderButton('premium')}
          </div>
          <div className="tier">
            <h3>Pro</h3>
            <ul>
              <li>Everything in Premium, plus:</li>
              <li>Unlimited AI interactions</li>
              <li>Priority response time</li>
            </ul>
            {renderButton('pro')}
          </div>
          <div className="tier">
            <h3>Ultimate</h3>
            <ul>
              <li>Everything in Pro, plus:</li>
              <li>Priority response times</li>
              <li>Access to beta features</li>
            </ul>
            {renderButton('ultimate')}
          </div>
        </div>
      </section>

      <section className="comparison">
        <h2>Comparison with Top LLM Models</h2>
        <table>
            <thead>
                <tr>
                    <th>Benchmark</th>
                    <th>StudyBuddy Dynamic API</th>
                    <th>Grok-2</th>
                    <th>Claude 3.5 Sonnet</th>
                    <th>Gemini Pro 1.5</th>
                    <th>Llama 3 405B</th>
                    <th>GPT-4o</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>GPQA</td>
                    <td>59.6%</td>
                    <td>56.0%</td>
                    <td>59.6%</td>
                    <td>46.2%</td>
                    <td>51.1%</td>
                    <td>53.6%</td>
                </tr>
                <tr>
                    <td>MMLU</td>
                    <td>88.7%</td>
                    <td>87.5%</td>
                    <td>88.3%</td>
                    <td>85.9%</td>
                    <td>88.6%</td>
                    <td>88.7%</td>
                </tr>
                <tr>
                    <td>MMLU-Pro</td>
                    <td>76.1%</td>
                    <td>75.5%</td>
                    <td>76.1%</td>
                    <td>69.0%</td>
                    <td>73.3%</td>
                    <td>72.6%</td>
                </tr>
                <tr>
                    <td>MATH§</td>
                    <td>76.6%</td>
                    <td>76.1%</td>
                    <td>71.1%</td>
                    <td>67.7%</td>
                    <td>73.8%</td>
                    <td>76.6%</td>
                </tr>
                <tr>
                    <td>HumanEval</td>
                    <td>92.0%</td>
                    <td>88.4%</td>
                    <td>92.0%</td>
                    <td>71.9%</td>
                    <td>89.0%</td>
                    <td>90.2%</td>
                </tr>
                <tr>
                    <td>MMMU</td>
                    <td>69.1%</td>
                    <td>66.1%</td>
                    <td>68.3%</td>
                    <td>62.2%</td>
                    <td>64.5%</td>
                    <td>69.1%</td>
                </tr>
                <tr>
                    <td>MathVista</td>
                    <td>67.7%</td>
                    <td>69.0%</td>
                    <td>67.7%</td>
                    <td>63.9%</td>
                    <td>—</td>
                    <td>63.8%</td>
                </tr>
                <tr>
                    <td>DocVQA</td>
                    <td>95.2%</td>
                    <td>93.6%</td>
                    <td>95.2%</td>
                    <td>93.1%</td>
                    <td>92.2%</td>
                    <td>92.8%</td>
                </tr>
            </tbody>
        </table>
      </section>

      <section className="real-world-impact">
        <h2>Transform Your Academic Journey</h2>
        <p>
          StudyBuddy isn't just about answering questions—it's about transforming how you learn. 
          Our users report significant improvements in their understanding of complex topics, 
          better retention of information, and increased confidence in their academic abilities.
        </p>
        {/* 
        Testimonial section commented out for now.
        Uncomment and customize as needed for future use.

        <div className="testimonial">
          <blockquote>
            "StudyBuddy helped me grasp quantum mechanics concepts I'd been struggling with for months. 
            The way it broke down complex ideas and related them to things I already understood was incredible."
          </blockquote>
          - Sarah K., Physics Major
        </div>
        */}
      </section>


      <section className="future-learning">
        <h2>The Future of Learning is Here</h2>
        <p>
          As AI continues to evolve, so does StudyBuddy. We're constantly refining our API, 
          expanding our knowledge base, and developing new features to ensure you're always 
          at the cutting edge of AI-assisted learning.
        </p>
        <Link to="/#features" className="cta-button">Start Your Journey with StudyBuddy</Link>
      </section>
    </div>
  );
};

export default StudyBuddyFeatures;