import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Home.css';
import { useAuth } from '../hooks/useAuth';
import { FaRocket, FaUserGraduate, FaBook, FaCheck } from 'react-icons/fa';
import useDocumentTitle from '../UseDocumentTitle';
import { createCheckoutSession, handleStripePortalRedirect } from '../services/api';

// Comment out the import for AITutorPreview
// const AITutorPreview = React.lazy(() => import('./Partials/AITutorPreview'));

const Home = () => {
    useDocumentTitle('StudyBuddy - AI Powered Learning');
    const navigate = useNavigate();
    const { isAuthenticated, user, subscription } = useAuth();

    console.log('User subscription:', subscription);

    const pricingTiers = [
        { 
            name: 'Free', 
            price: { usd: 'Free', cad: 'Free' },
            features: [
                'Access to core AI capabilities',
                'Daily interaction limits of 3 chats'
            ]
        },
        { 
            name: 'Premium', 
            price: { 
                usd: { monthly: '$5.59', annual: '$49.99' },
                cad: { monthly: '$6.99', annual: '$59.99' }
            },
            freeTrial: '7 days',
            features: [
                'Access to core AI capabilities',
                'Increased interaction limits',
                'General study planning tools'
            ]
        },
        { 
            name: 'Pro', 
            price: { 
                usd: { monthly: '$12.99', annual: '$119.99' },
                cad: { monthly: '$15.99', annual: '$139.99' }
            },
            freeTrial: '7 days',
            features: [
                'Everything in Premium, plus:',
                'Unlimited AI interactions',
            ]
        },
        { 
            name: 'Ultimate', 
            price: { 
                usd: { monthly: '$18.99', annual: '$159.99' },
                cad: { monthly: '$21.99', annual: '$189.99' }
            },
            freeTrial: '7 days', 
            features: [
                'Everything in Pro, plus:',
                'Priority response times',
                'Access to beta features'
            ]
        }
    ];

    const [currency, setCurrency] = useState('usd'); // Default to USD
    const [billingCycle, setBillingCycle] = useState('monthly');

    useEffect(() => {
        // This is a simple example. In a real app, you might want to use a more robust geolocation service.
        const userLanguage = navigator.language || navigator.userLanguage;
        if (userLanguage.toLowerCase().includes('ca')) {
            setCurrency('cad');
        } else {
            setCurrency('usd');
        }
    }, []);

    const CurrencyAndBillingSelector = () => (
        <div className="pricing-controls">
            <div className="toggle-container">
                <span className={currency === 'usd' ? 'active' : ''}>USD</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={currency === 'cad'}
                        onChange={() => setCurrency(currency === 'usd' ? 'cad' : 'usd')}
                    />
                    <span className="slider round"></span>
                </label>
                <span className={currency === 'cad' ? 'active' : ''}>CAD</span>
            </div>
            <div className="toggle-container">
                <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={billingCycle === 'annual'}
                        onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
                    />
                    <span className="slider round"></span>
                </label>
                <span className={billingCycle === 'annual' ? 'active' : ''}>Annual</span>
            </div>
        </div>
    );

    const handleSubscription = async (tier, interval = 'monthly', currency = 'usd') => {
        if (!isAuthenticated) {
            navigate('/signup', { state: { selectedTier: tier, interval, currency } });
            return;
        }

        try {
            if(subscription.tier !== "free"){
                handleStripePortalRedirect();
                return;
            }
            const response = await createCheckoutSession(tier, interval, currency);
            if (response.url) {
                window.location.href = response.url;
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            // TODO: Implement error handling (e.g., show an error message to the user)
        }
    };

    const renderButton = (tier) => {
        if (!user) {
            return (
                <button 
                    onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
                    className="signup-button"
                >
                    {tier.name.toLowerCase() === 'free' ? 'Get Started' : `Choose ${tier.name}`}
                </button>
            );
        }

        if (subscription && subscription.tier !== 'free') {
            if (subscription.tier.toLowerCase() === tier.name.toLowerCase()) {
                return (
                    <Link to="/settings" className="signup-button">
                        Manage Subscription
                    </Link>
                );
            } else {
                return (
                    <button 
                        className="signup-button" 
                        style={{visibility: 'hidden'}} 
                        disabled
                    >
                        Invisible
                    </button>
                );
            }
        }

        // For free users
        return (
            <button 
                onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
                className="signup-button"
            >
                {tier.name.toLowerCase() === 'free' ? 'Current Plan' : `Choose ${tier.name}`}
            </button>
        );
    };

    return (
        <div className="home-page">
            <header className="hero">
                <h1>Elevate Your Learning with StudyBuddy</h1>
                <p>Your personal AI-powered study companion available 24/7</p>
                    <Link to={isAuthenticated ? "/chats/new" : "/signup"} className="cta-button primary">
                        {isAuthenticated ? "Start Learning" : "Begin Your Journey"}
                    </Link>
            </header>

            <section id="features" className="features">
                <h2>Why StudyBuddy is Your Ultimate Learning Partner</h2>
                <div className="feature-grid">
                    <div className="feature-item">
                        <FaRocket size={48} className='feature-icons'/>
                        <h3>24/7 Intelligent Assistance</h3>
                        <p>Get instant help anytime, anywhere with our AI-powered tutor</p>
                    </div>
                    <div className="feature-item">
                        <FaUserGraduate size={48} className='feature-icons'/>
                        <h3>Personalized Learning</h3>
                        <p>Adaptive lessons tailored to your unique learning style and pace</p>
                    </div>
                    <div className="feature-item">
                        <FaBook size={48} className='feature-icons'/>
                        <h3>Comprehensive Coverage</h3>
                        <p>From mathematics to literature, we've got every subject mastered</p>
                    </div>
                </div>
            </section>

            <section id="pricing" className="pricing">
                <h2>Choose the Perfect Plan for Your Academic Success</h2>
                <CurrencyAndBillingSelector />
                <div className="pricing-grid">
                    {pricingTiers.map((tier) => (
                        <div key={tier.name} className={`pricing-item ${tier.name === 'Pro' ? 'featured' : ''}`}>
                            <h3>{tier.name}</h3>
                            {tier.name !== 'Free' ? (
                                <>
                                    <p className="price">
                                        {tier.price[currency][billingCycle]}
                                        <span className="billing-cycle">
                                            {billingCycle === 'monthly' ? '/month' : '/year'}
                                        </span>
                                    </p>
                                    {billingCycle === 'annual' && (
                                        <p className="annual-savings">
                                            Save {(() => {
                                                const monthlyPrice = parseFloat(tier.price[currency].monthly.replace(/[^0-9.]/g, ''));
                                                const annualPrice = parseFloat(tier.price[currency].annual.replace(/[^0-9.]/g, ''));
                                                return ((monthlyPrice * 12 - annualPrice) / (monthlyPrice * 12) * 100).toFixed(0);
                                            })()}%
                                        </p>
                                    )}
                                </>
                            ) : (
                                <p className="price">{tier.price[currency]}</p>
                            )}
                            {tier.freeTrial && <p className="free-trial">{tier.freeTrial} free trial</p>}
                            <ul className="feature-list">
                                {tier.features.map((feature, index) => (
                                    <li key={index}><FaCheck className="feature-check" /> {feature}</li>
                                ))}
                            </ul>
                            {renderButton(tier)}
                        </div>
                    ))}
                </div>

                <Link to="/features" className="cta-button secondary">
                        Learn More
                </Link>
            </section>
            
            {/* Comment out the AITutorPreview section */}
            {/*
            <Suspense fallback={<div>Loading...</div>}>
                <AITutorPreview />
            </Suspense>
            */}
        </div>
    );
};

export default Home;