import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { TextField, Button, Typography, Paper, Box } from '@mui/material';
import { updateProfile } from '../../services/api';

const ProfileUpdate = () => {
  const { user, checkUser } = useAuth();
  const [profileData, setProfileData] = useState({ username: user.username, email: user.email });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    try {
      await updateProfile(profileData);
      setSuccess(true);
      checkUser(); // Update the user data in the auth context
    } catch (error) {
      setError(error.response?.data?.error || 'Profile update failed. Please try again.');
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h4" gutterBottom>Update Profile</Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="success">Profile updated successfully!</Typography>}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          margin="normal"
          name="username"
          label="Username"
          value={profileData.username}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="email"
          label="Email"
          type="email"
          value={profileData.email}
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
          Update Profile
        </Button>
      </Box>
    </Paper>
  );
};

export default ProfileUpdate;