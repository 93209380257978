/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Send, Menu, Settings, Home, Zap } from 'lucide-react';
import Sidebar from './Partials/Sidebar';
import '../Styles/Chat.css';
import { fetchMessages, sendMessage, createNewChat } from '../services/api';
import { shuffle } from 'lodash';
import { allSuggestions } from './Data/AllSuggestions';
import { MarkdownRenderer, AIResponseRenderer } from './Partials/LLMTextRenderer';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import useDocumentTitle from '../UseDocumentTitle';

const Chats = ({ chats, isChatsLoading, subscription, remainingChats }) => {
  useDocumentTitle('Chats - StudyBuddy');
  const queryClient = useQueryClient();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const inputRef = useRef('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    return window.innerWidth > 768;
  });
  const messagesEndRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [updateSidebarTitle, setUpdateSidebarTitle] = useState(null);
  const [chatIdToUse, setChatIdToUse] = useState(null);

  useEffect(() => {
    setSuggestions(shuffle(allSuggestions).slice(0, 4));
  }, []);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const { data: messagesData, isLoading: isMessagesLoading } = useQuery(
    ['messages', chatId],
    () => fetchMessages(chatId),
    {
      enabled: !!chatId && chatId !== 'new',
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      onSuccess: (data) => {
        setMessages(data);
        localStorage.setItem(`chat_${chatId}`, JSON.stringify(data));
      },
    }
  );

  useEffect(() => {
    if (chatId && chatId !== 'new') {
      const cachedMessages = localStorage.getItem(`chat_${chatId}`);
      if (cachedMessages) {
        setMessages(JSON.parse(cachedMessages));
      }
    } else if (chatId === 'new') {
      setMessages([]);
    }
  }, [chatId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleUpdateTitle = useCallback((updateFunction) => {
    console.log('handleUpdateTitle called', { updateFunction });
    setUpdateSidebarTitle(() => updateFunction);
  }, []);

  useEffect(() => {
    console.log('updateSidebarTitle changed', { updateSidebarTitle });
  }, [updateSidebarTitle]);

  useEffect(() => {
    if (chatId === 'new') {
      setChatIdToUse(null);
    } else if (chatId) {
      setChatIdToUse(chatId);
    }
  }, [chatId]);

  const handleSubmit = async (e) => {
    console.log('handleSubmit called', { e, input: inputRef.current });
    e?.preventDefault?.();
    if (!inputRef.current.trim()) {
      console.log('Input is empty, returning');
      return;
    }
    try {
      console.log('Proceeding with submission', { chatIdToUse, input: inputRef.current });
      let currentChatId = chatIdToUse;
      if (!currentChatId) {
        const newChat = await createNewChat();
        currentChatId = newChat.id;
        setChatIdToUse(currentChatId);
        // After creating a new chat or sending a message
        queryClient.invalidateQueries('chats');
      }

      const isFirstMessage = messages.length === 0;

      const currentInput = inputRef.current;
      const currentTimestamp = new Date().toISOString();
      const userMessage = { id: Date.now(), content: currentInput, is_ai: false, timestamp: currentTimestamp };
      const aiMessage = { id: Date.now() + 1, content: '', is_ai: true, timestamp: currentTimestamp };
      
      setMessages(prevMessages => [...prevMessages, userMessage, aiMessage]);
      setInput('');
      inputRef.current = '';

      const stream = await sendMessage(currentChatId, currentInput);
      const reader = stream.getReader();
      const decoder = new TextDecoder();
      
      let aiResponse = '';
      let newTitle = null;
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(5));
              if (data.content) {
                aiResponse += data.content;
                setMessages(prevMessages => prevMessages.map(msg => 
                  msg.id === aiMessage.id ? { ...msg, content: aiResponse } : msg
                ));
              }
              if (data.newTitle && isFirstMessage) {
                newTitle = data.newTitle;
                // Update the chat title in the UI or state
                queryClient.setQueryData(['chats'], (oldData) => {
                  if (!oldData || !Array.isArray(oldData)) return [{ id: currentChatId, title: newTitle }];
                  return oldData.map(chat => 
                    chat.id === currentChatId ? { ...chat, title: newTitle } : chat
                  );
                });
              }
            } catch (error) {
              console.error('Error parsing SSE data:', error);
            }
          }
          if (line.startsWith('event: end')) {
            // Handle end of stream
            break;
          }
        }
      }

      // After the streaming is complete:
      queryClient.setQueryData(['messages', currentChatId], (oldData) => {
        const newMessages = [userMessage, { ...aiMessage, content: aiResponse }];
        if (Array.isArray(oldData)) {
          return [...oldData, ...newMessages];
        } else {
          return newMessages;
        }
      });

      // If we didn't receive a new title during streaming, update with a default title
      if (!newTitle && isFirstMessage) {
        newTitle = 'New Chat';
        queryClient.setQueryData(['chats'], (oldData) => {
          if (!oldData || !Array.isArray(oldData)) return [{ id: currentChatId, title: newTitle }];
          return oldData.map(chat => 
            chat.id === currentChatId ? { ...chat, title: newTitle } : chat
          );
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      if (error.message.includes('Daily chat limit reached')) {
        toast.error('You have reached your daily chat limit. Please upgrade your subscription to continue chatting.');
      } else {
        toast.error('An error occurred while sending your message. Please try again.');
      }
    }
  };

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => !prev);
  }, []);

  const renderMessage = useCallback((msg) => {
    const isUser = !msg.is_ai;
    const messageClass = `message ${isUser ? 'user' : 'ai'}`;

    return (
      <div key={msg.id} className={messageClass}>
        <div className="message-content">
          {isUser ? (
            <MarkdownRenderer content={msg.content} />
          ) : (
            <AIResponseRenderer content={msg.content} />
          )}
          <div className="message-info">
            <span className="message-sender">{isUser ? 'You' : 'StudyBuddy'}</span>
            <span className="message-timestamp">{new Date(msg.timestamp).toLocaleTimeString()}</span>
          </div>
        </div>
      </div>
    );
  }, []);

  const renderDefaultDisplay = () => (
    <div className="default-display">
      <div className="welcome-section">
        <h2>Welcome to StudyBuddy!</h2>
        <p>Your AI-powered learning assistant. Ask anything or try a suggestion below.</p>
      </div>
      <div className="suggestions-section">
        <h3>Try asking about:</h3>
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li 
              key={index} 
              onClick={() => remainingChats > 0 ? submitSuggestion(suggestion) : null}
              className={`suggestion-item ${remainingChats === 0 ? 'disabled' : ''}`}
            >
              <Zap size={16} className="suggestion-icon" />
              <span>{suggestion}</span>
            </li>
          ))}
        </ul>
      </div>
      {remainingChats === 0 && (
        <div className="chat-limit-reached">
          <p>You've reached your daily chat limit. <Link to="/pricing">Upgrade your plan</Link> for more chats!</p>
        </div>
      )}
    </div>
  );

  const submitSuggestion = (suggestion) => {
    console.log('submitSuggestion called', { suggestion });
    setInput(suggestion);
    inputRef.current = suggestion;
    console.log('Input set, calling handleSubmit');
    handleSubmit({ preventDefault: () => {} });
  };

  const renderChatLimitBanner = () => {
    if (remainingChats === null || remainingChats > 4) return null;
    
    let message = '';
    if (remainingChats === 0) {
      message = (
        <>
          You have no chats left today. <Link to="/pricing">Consider upgrading your plan for more!</Link>
        </>
      );
    } else {
      message = `You have ${remainingChats} chat${remainingChats === 1 ? '' : 's'} left today.`;
    }

    return (
      <div className="chat-limit-banner">
        {message}
      </div>
    );
  };

  return (
    <div className={`chat-page ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <Sidebar 
        isOpen={isSidebarOpen} 
        toggleSidebar={toggleSidebar} 
        updateTitle={handleUpdateTitle}
        chats={chats}
        isChatsLoading={isChatsLoading}
        remainingChats={remainingChats}
      />
      <div className={`chat-area ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="chat-header">
          <button className="menu-button" onClick={toggleSidebar} aria-label="Toggle sidebar">
            <Menu size={24} />
          </button>
          <h1 className="chat-title">
            <Link to="/" className="home-button" aria-label="Go to home">
              <Home size={24} />
            </Link>
          </h1>
          <div className="header-actions">
            <Link to="/settings" className="settings-button" aria-label="Open settings">
              <Settings size={24} />
            </Link>
          </div>
        </div>
        <div className="messages-container">
          <div className="messages-content">
            {messages.length === 0 ? (
              renderDefaultDisplay()
            ) : (
              messages.map(renderMessage)
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className="input-container">
          <div className="input-area">
            <form className="input-wrapper" onSubmit={handleSubmit}>
              <input
                ref={inputRef}
                type="text"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  inputRef.current = e.target.value;
                }}
                placeholder="Type your message here..."
                aria-label="Chat input"
                className="chat-input" 
              />
              <button 
                type="submit" 
                disabled={!input.trim() || remainingChats === 0} 
                aria-label="Send message"
                className="send-button"
              >
                <Send size={20} />
              </button>
            </form>
          </div>
          {remainingChats <= 4 && (
            renderChatLimitBanner()
          )}

        </div>
      </div>
    </div>
  );
};

export default React.memo(Chats);