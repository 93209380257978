/* eslint-disable no-unused-vars */
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate } from 'react-router-dom';
import SignUp from './Components/Auth/Signup';
import Login from './Components/Auth/Login.jsx';
import Settings from './Components/Settings.jsx';
import ProfileUpdate from './Components/Auth/ProfileUpdate';
import PrivateRoute from './Components/Auth/PrivateRoute';
import Chats from './Components/Chats';
import StudyPlans from './Components/Auth/StudyPlans';
import { AuthProvider, useAuth } from './hooks/useAuth';
import './Styles/GlobalStyles.css';
import './App.css';
import { CssBaseline, Container} from '@mui/material';
import Home from './Components/Home';
import DevUpdates from './Components/Info Pages/DevUpdates';
import Features from './Components/Features';
import ContactUs from './Components/ContactUs';
import PrivacyPolicy from './Components/Info Pages/PrivacyPolicy'
import Footer from './Components/Partials/Footer';
import Pricing from './Components/Pricing';
import NavBar from './Components/Partials/NavBar.jsx';
import NotFound from './Components/Info Pages/404';
import ChatWithPDF from './Components/ChatWIthPDF.jsx'
import LearningOptions from './Components/LearningOptions';
import LoadingOverlay from './Components/Partials/LoadingOverlay';
import { QueryClient, QueryClientProvider } from 'react-query';
import TermsofService from './Components/Info Pages/TermsofService.jsx';
import SitewideBanner from './Components/Partials/SitewideBanner'; // Add this import
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/Toast.css';  // Add this import
import PaymentConfirmation from './Components/PaymentConfirmation';

const queryClient = new QueryClient();

const PublicRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? <Navigate to="/" replace /> : children;
};

const AppContent = () => {
  const { isLoading, globalLoading, user, subscription, remainingChats, chats, isChatsLoading } = useAuth();
  const location = useLocation();
  const isChatsPage = /^\/chats/.test(location.pathname);
  const isPDFPage = /^\/chatwithpdf/.test(location.pathname);
  const showNavBar = !isChatsPage && !isPDFPage;
  const showFooter = !isChatsPage && !isPDFPage;

  console.log('User:', user);
  console.log('Chats:', chats);
  console.log('Subscription:', subscription);
  console.log('Remaining chats:', remainingChats);

  if (isLoading || globalLoading) {
    console.log('App is loading');
    return <LoadingOverlay />;
  }

  return (
    <div className="app-container">

      <main className="content">
        <CssBaseline />
        <Container maxWidth disableGutters>
          {showNavBar && <NavBar />}
          <Routes>
            <Route 
              path="/chats/:chatId" 
              element={
                <PrivateRoute>
                  <Chats 
                    chats={chats} 
                    isChatsLoading={isChatsLoading} 
                    subscription={subscription}
                    remainingChats={remainingChats}
                  />
                </PrivateRoute>
              } 
            />
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/dev-updates" element={<DevUpdates />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsofService />} />
            <Route path="/features" element={<Features />} />
            <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/profile-update" element={<PrivateRoute><ProfileUpdate /></PrivateRoute>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </Container>
      </main>
      {showFooter && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <AppContent />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;