import React, { useState } from 'react';
import '../Styles/ContactUs.css';
import { FaEnvelope } from 'react-icons/fa';
import useDocumentTitle from '../UseDocumentTitle';
import { sendContactMessage } from '../services/api';
import { toast } from 'react-toastify';
import Toast from './Partials/Toast';

const ContactUs = () => {
  useDocumentTitle('StudyBuddy - Contact Us');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await sendContactMessage(formData);
      setFormData({ name: '', email: '', subject: '', message: '' });
      toast(<Toast type="success" message="Thank you for your message. We will get back to you via email soon!" />);
    } catch (error) {
      console.error('Error sending message:', error);
      toast(<Toast type="error" message="Failed to send message. Please try again later." />);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-us-page">
      <header className="contact-hero">
        <h1>Get in Touch</h1>
        <p>Have a question or need assistance? Send us an email and we'll get back to you as soon as possible.</p>
      </header>

      <section className="contact-form">
        <h2>Send Us a Message</h2>
        <div className="email-info">
          <FaEnvelope className="email-icon" />
          <p>You can also reach us directly at: <strong>customerservice.studybuddy.lekan.ca</strong></p>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </section>
    </div>
  );
};

export default ContactUs;