/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { Plus, X, Edit, LogOut, User, BookOpen, MessageCircle, ChevronLeft, ChevronRight, FileText } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import '../../Styles/Sidebar.css'
import { fetchChats, fetchPdfChats, addChat, removeChat, removePdfChat, renameChat, renamePDFChat } from '../../services/api';
import { useQuery, useQueryClient } from 'react-query';
import LoadingOverlay from './LoadingOverlay';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user, logout, chats, isChatsLoading, fetchUserChats, updateChatTitle } = useAuth();
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingChatTitle, setEditingChatTitle] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    fetchUserChats();
  }, [fetchUserChats]);

  const handleAddChat = async () => {
    try {
      const newChat = await addChat();
      fetchUserChats(); // Refetch chats after adding a new one
      navigate(`/chats/${newChat.id}`);
      if (window.innerWidth <= 768) {
        toggleSidebar();
      }
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };
  const handleRemoveChat = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await removeChat(id);
      queryClient.invalidateQueries('chats');
      queryClient.removeQueries(['messages', id]);
      localStorage.removeItem(`chat_${id}`);
      fetchUserChats(); // Refetch chats after removing one

      const currentPath = window.location.pathname;
      const currentChatId = currentPath.split('/').pop();

      if (id === currentChatId) {
        if (chats.length > 1) {
          const sortedChats = chats.filter(chat => chat.id !== id).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          const mostRecentChat = sortedChats[0];
          navigate(`/chats/${mostRecentChat.id}`);
        } else {
          navigate('/chats/new');
        }
      }
    } catch (error) {
      console.error('Error removing chat:', error);
    }
  };

  const handleRenameChat = async (id) => {
    try {
      await renameChat(id, editingChatTitle);
      updateChatTitle(id, editingChatTitle);
      setEditingChatId(null);
      setEditingChatTitle('');
    } catch (error) {
      console.error('Error renaming chat:', error);
    }
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <aside className={`sidebar ${isOpen ? 'open' : 'collapsed'}`}>
      <div className="sidebar-header">
        <User size={20} />
        <span className="user-email">{user?.email}</span>
        <button className="collapse-btn" onClick={toggleSidebar}>
          <ChevronLeft size={24} />
        </button>
      </div>
      {isOpen && (
        <>
          <nav className="sidebar-nav">
            <h3><MessageCircle size={16} /> Chats</h3>
            <section className="scrollable-section">
              {isChatsLoading ? (
                <LoadingOverlay isLoading={true} />
              ) : (
                <>
                  {chats.length > 0 ? (
                    <ul>
                      {chats.map((chat) => (
                        <li key={chat.id}>
                          {editingChatId === chat.id ? (
                            <form onSubmit={(e) => { e.preventDefault(); handleRenameChat(chat.id); }}>
                              <input
                                value={editingChatTitle}
                                onChange={(e) => setEditingChatTitle(e.target.value)}
                                onBlur={() => handleRenameChat(chat.id)}
                                autoFocus
                              />
                            </form>
                          ) : (
                            <NavLink to={`/chats/${chat.id}`}>
                              <span className="chat-title">{chat.title || 'Untitled Chat'}</span>
                              <div className="button-group">
                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditingChatId(chat.id); setEditingChatTitle(chat.title); }} className="edit-btn">
                                  <Edit size={14} />
                                </button>
                                <button onClick={(e) => handleRemoveChat(chat.id, e)} className="remove-btn">
                                  <X size={14} />
                                </button>
                              </div>
                            </NavLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Chats Available</p>
                  )}
                </>
              )}
            </section>
            <button onClick={handleAddChat} className="add-btn"><Plus size={14} /> New Chat</button>
          </nav>
          <button onClick={handleLogout} className="logout-btn">
            <LogOut size={16} /> Logout
          </button>
        </>
      )}
    </aside>
  );
};

export default React.memo(Sidebar);