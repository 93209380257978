/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { signUp, createCheckoutSession, login } from '../../services/api';
import '../../Styles/AuthForms.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUp = () => {
  const [userData, setUserData] = useState({name: '', email: '', password: '', phone_number: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTier = location.state?.selectedTier || 'Free';

  useEffect(() => {
    console.log('Selected tier:', selectedTier); 
    if (selectedTier !== 'Free') {
      setSuccess(`You're signing up for the ${selectedTier} plan!`);
    }
  }, [selectedTier]);

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Password validation
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d|.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(userData.password)) {
      setError('Password must be at least 8 characters long and contain a number or symbol.');
      return;
    }

    console.log('Submitting signup form for tier:', selectedTier);
    try {
      const signUpResponse = await signUp(userData);
      setSuccess('Registration successful!');
      
      // Automatically log in the user
      await login(userData.email, userData.password);

      if (selectedTier !== 'Free') {
        try {
          console.log('Creating checkout session for tier:', selectedTier);
          const paymentLinkResponse = await createCheckoutSession(selectedTier);
          console.log('Checkout session response:', paymentLinkResponse);
          if (paymentLinkResponse.url) {
            window.location.href = paymentLinkResponse.url;
          } else {
            console.error('Unexpected payment link response format:', paymentLinkResponse);
            setError('Error creating subscription. Please try again.');
          }
        } catch (subError) {
          console.error('Subscription error:', subError);
          setError('Error creating subscription. Please try again.');
        }
      } else {
        navigate('/learning-options');
      }
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'Registration failed. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
        <div className="branding">
          <Link to="/" className="logo">StudyBuddy</Link>
        </div>
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={userData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={userData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                required
                value={userData.password}
                onChange={handleChange}
              />
              <button 
                type="button" 
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="phone_number">Phone Number (optional)</label>
            <input
              type="tel"
              id="phone_number"
              name="phone_number"
              value={userData.phone_number}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="submit-button">
            Sign Up {selectedTier !== 'Free' ? `for ${selectedTier}` : ''}
          </button>
        </form>
        <p className="auth-link">
          Already have an account? <Link to="/login">Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;