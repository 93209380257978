/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import katex from 'katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CodeDisplayModal from './CodeDisplayModal';

export const MarkdownRenderer = ({ content }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw, [rehypeKatex, { strict: false }]]}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              language={match[1]}
              PreTag="div"
              style={vscDarkPlus}
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        },
        math: ({value}) => <MathRenderer math={value} />,
        inlineMath: ({value}) => <MathRenderer math={value} inline />,
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

const MathRenderer = ({ math, inline = false }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      try {
        katex.render(math, ref.current, {
          throwOnError: false,
          errorColor: '#ff0000',
          displayMode: !inline,
          strict: false,
          trust: true,
        });
      } catch (error) {
        console.error('KaTeX rendering error:', error);
        ref.current.textContent = math;
      }
    }
  }, [math, inline]);

  return <span ref={ref} className={inline ? 'math-inline' : 'math-block'} />;
};

export const parseResponse = (text) => {
  const lines = text.split('\n');
  const parsedContent = [];
  let currentList = null;
  let inCodeBlock = false;
  let codeBlockContent = '';
  let codeBlockLanguage = '';
  let inTable = false;
  let tableHeaders = [];
  let tableRows = [];

  const processMathExpression = (line) => {
    return line.replace(/\\\(/g, '$$')
                .replace(/\\\)/g, '$$')
                .replace(/\^(\w+)/g, '^{$1}')
                .replace(/\^(\d+)/g, '^{$1}')
                .replace(/([_^])([a-zA-Z0-9]+)/g, '$1{$2}')
                .replace(/\\int/g, '\\int ')
                .replace(/\\lim_/g, '\\lim\\limits_')
                .replace(/([^$])(\$[^$]+\$)([^$])/g, '$1$$2$3');
  };

  const processInlineCode = (line) => {
    const parts = line.split('`');
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return { type: 'inline-code', content: part };
      }
      return { type: 'text', content: part };
    });
  };

  const processListItem = (line, isOrdered = false) => {
    const level = (line.match(/^\s*/)[0].length / 2) + 1;
    const content = line.replace(/^(\d+\.|\•|\-)\s*/, '').trim();

    if (!currentList || (isOrdered && /^\d+\./.test(line.trim()))) {
      if (currentList) parsedContent.push(currentList);
      currentList = { type: isOrdered ? 'ordered-list' : 'list', items: [] };
    }
    currentList.items.push({ type: 'list-item', content, level });
  };

  lines.forEach((line, index) => {
    const trimmedLine = line.trim();

    if (trimmedLine.startsWith('```')) {
      if (inCodeBlock) {
        parsedContent.push({ type: 'code-block', content: codeBlockContent.trim(), language: codeBlockLanguage });
        inCodeBlock = false;
        codeBlockContent = '';
        codeBlockLanguage = '';
      } else {
        inCodeBlock = true;
        codeBlockLanguage = trimmedLine.slice(3);
      }
      return;
    }

    if (inCodeBlock) {
      codeBlockContent += line + '\n';
      return;
    }

    if (trimmedLine.startsWith('|') && trimmedLine.endsWith('|')) {
      if (!inTable) {
        inTable = true;
        tableHeaders = trimmedLine.split('|').slice(1, -1).map(header => header.trim());
      } else if (trimmedLine.includes('---')) {
        // This is the separator line, skip it
      } else {
        tableRows.push(trimmedLine.split('|').slice(1, -1).map(cell => cell.trim()));
      }
    } else if (inTable) {
      // End of table
      parsedContent.push({ type: 'table', headers: tableHeaders, rows: tableRows });
      inTable = false;
      tableHeaders = [];
      tableRows = [];
    } else {
      const processedLine = processMathExpression(trimmedLine);

      if (processedLine.includes('`')) {
        parsedContent.push({ type: 'paragraph', content: processInlineCode(processedLine) });
      } else if (processedLine.match(/\*\*.*?\*\*/)) {
        const parts = processedLine.split(/(\*\*.*?\*\*)/);
        parsedContent.push({
          type: 'paragraph',
          content: parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              return { type: 'strong', content: part.slice(2, -2) };
            }
            return { type: 'text', content: part };
          }),
        });
      } else if (processedLine.match(/^#{1,6}\s/)) {
        const level = processedLine.match(/^#+/)[0].length;
        parsedContent.push({ type: 'heading', level, content: processedLine.replace(/^#+\s/, '') });
      } else if (processedLine.startsWith('•') || processedLine.startsWith('-') || processedLine.startsWith('.')) {
        processListItem(processedLine);
      } else if (/^\d+\./.test(processedLine)) {
        processListItem(processedLine, true);
      } else if (currentList && currentList.type === 'ordered-list' && !trimmedLine.match(/^\d+\./)) {
        // This is a continuation of the previous list item
        currentList.items[currentList.items.length - 1].content += ' ' + trimmedLine;
      } else if (processedLine.startsWith('> ')) {
        parsedContent.push({ type: 'blockquote', content: processedLine.slice(2) });
      } else if (processedLine.match(/\[.*?\]\(.*?\)/)) {
        const linkMatch = processedLine.match(/\[(.*?)\]\((.*?)\)/);
        parsedContent.push({ type: 'link', text: linkMatch[1], url: linkMatch[2] });
      } else if (processedLine === '---') {
        parsedContent.push({ type: 'horizontal-rule' });
      } else if (processedLine) {
        if (currentList) {
          parsedContent.push(currentList);
          currentList = null;
        }
        parsedContent.push({ type: 'paragraph', content: processedLine });
      }
    }
  });

  if (currentList) parsedContent.push(currentList);

  return parsedContent;
};

export const renderParsedContent = (parsedContent) => {
  return (
    <div className="ai-response-content">
      {parsedContent.map((item, index) => {
        switch (item.type) {
          case 'heading':
            const HeadingTag = `h${item.level}`;
            return <HeadingTag key={index} className={`ai-response-heading ai-response-heading-${item.level}`}>{item.content}</HeadingTag>;
          case 'paragraph':
            if (Array.isArray(item.content)) {
              return (
                <p key={index} className="ai-response-paragraph">
                  {item.content.map((part, partIndex) => 
                    part.type === 'inline-code' 
                      ? <code key={partIndex} className="ai-response-inline-code">{part.content}</code> 
                      : part.type === 'strong' 
                        ? <strong key={partIndex} className="ai-response-strong">{part.content}</strong> 
                        : part.content
                  )}
                </p>
              );
            }
            return <p key={index} className="ai-response-paragraph">{item.content}</p>;
          case 'strong':
            return <strong key={index} className="ai-response-strong">{item.content}</strong>;
          case 'list':
          case 'ordered-list':
            const ListTag = item.type === 'list' ? 'ul' : 'ol';
            return (
              <ListTag key={index} className="ai-response-list">
                {item.items.map((listItem, listIndex) => (
                  <li key={listIndex} style={{ marginLeft: `${(listItem.level - 1) * 20}px` }} className="ai-response-list-item">
                    {listItem.content}
                  </li>
                ))}
              </ListTag>
            );
          case 'blockquote':
            return <blockquote key={index} className="ai-response-blockquote">{item.content}</blockquote>;
          case 'link':
            return <a key={index} href={item.url} target="_blank" rel="noopener noreferrer" className="ai-response-link">{item.text}</a>;
          case 'horizontal-rule':
            return <hr key={index} className="ai-response-hr" />;
          case 'code-block':
            return (
              <CodeDisplayModal
                key={index}
                code={item.content}
                language={item.language}
              />
            );
          case 'table':
            return (
              <div className="table-wrapper" key={index}>
                <table className="ai-response-table">
                  <thead>
                    <tr>
                      {item.headers.map((header, headerIndex) => (
                        <th key={headerIndex}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export const AIResponseRenderer = React.memo(({ content }) => {
  const parsedContent = useMemo(() => parseResponse(content), [content]);
  return renderParsedContent(parsedContent);
});