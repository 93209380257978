/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import {FaCode, FaInfoCircle, FaEnvelope, FaBars, FaTimes, FaComments, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const NavBar = () => {
    const { isAuthenticated, user, logout } = useAuth();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleNav = () => setIsNavOpen(!isNavOpen);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div>
            <nav className="top-nav">
                <Link to="/" className="logo">StudyBuddy</Link>
                <div className={`nav-links ${isNavOpen ? 'open' : ''}`}>
                    <Link to="/features" className="nav-link" onClick={toggleNav}>
                        <FaInfoCircle /> How It Works
                    </Link>
                    <Link to="/chats/new" className="nav-link" onClick={toggleNav}>
                        <FaComments /> Chats
                    </Link>
                    <Link to="/contact" className="nav-link" onClick={toggleNav}>
                        <FaEnvelope /> Contact Us
                    </Link>
                    {isAuthenticated ? (
                        <div className="settings-dropdown" ref={dropdownRef}>
                            <button onClick={toggleDropdown} className="settings-button">
                                <FaCog /> Settings
                            </button>
                            {isDropdownOpen && (
                                <div className="dropdown-menu">
                                    <Link to="/settings" className="dropdown-item">Profile</Link>
                                    <button onClick={logout} className="dropdown-item logout-button">
                                        <FaSignOutAlt /> Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to="/login" className="sign-in-button">Sign In</Link>
                    )}
                </div>
            </nav>

            <nav className="top-nav2">
                <Link to="/" className="logo">StudyBuddy</Link>
                {isAuthenticated ? (
                    <div className="authenticated-actions">
                        <Link to="/chats/new" className="profile-button">Chats</Link>
                    </div>
                ) : (
                    <Link to="/login" className="sign-in-button">Sign In</Link>
                )}
                <button className="nav-toggle" onClick={toggleNav}>
                    {isNavOpen ? <FaTimes /> : <FaBars />}
                </button>
                <div className={`nav-links ${isNavOpen ? 'open' : ''}`}>
                    <Link to="/features" className="nav-link" onClick={toggleNav}>
                        <FaInfoCircle /> How It Works
                    </Link>
                    <Link to="/dev-updates" className="nav-link" onClick={toggleNav}>
                        <FaCode /> Dev Updates
                    </Link>
                    <Link to="/contact" className="nav-link" onClick={toggleNav}>
                        <FaEnvelope /> Contact Us
                    </Link>
                    <Link to="/settings" className="nav-link">
                        <FaCog /> Settings
                    </Link>
                    {isAuthenticated && (
                        <Link to="/logout" className="nav-link logout-button" onClick={(e) => { e.preventDefault(); logout(); }}>
                            <FaSignOutAlt /> Logout
                        </Link>
                    )}
                </div>
            </nav>
        </div>
    );
};

export default NavBar;