import React from 'react';
import '../../Styles/Policy.css';

const TermsofService = () => {
  return (
    <div className="policy-container">
      <h1>Terms of Service</h1>
      <p>Last updated: {new Date().toDateString()}</p>
      
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using StudyBuddy, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.</p>
      </section>
      
      <section>
        <h2>2. Description of Service</h2>
        <p>StudyBuddy is an AI-powered study companion that provides personalized learning assistance, chat-based tutoring, and PDF document analysis for educational purposes.</p>
      </section>
      
      <section>
        <h2>3. User Accounts</h2>
        <p>To use certain features of StudyBuddy, you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
      </section>
      
      <section>
        <h2>4. Subscription and Payments</h2>
        <p>StudyBuddy offers various subscription tiers. By subscribing, you agree to pay the fees associated with your chosen tier. Payments are processed securely through Stripe.</p>
      </section>
      
      <section>
        <h2>5. Content and Conduct</h2>
        <p>You are solely responsible for the content you submit to StudyBuddy. Do not upload any illegal, offensive, or copyrighted material without permission. We reserve the right to remove any content that violates these terms.</p>
      </section>
      
      <section>
        <h2>6. Intellectual Property</h2>
        <p>The StudyBuddy service, including its software, design, and content, is protected by copyright and other intellectual property laws. You may not copy, modify, or distribute any part of the service without our prior written consent.</p>
      </section>
      
      <section>
        <h2>7. Privacy and Data Protection</h2>
        <p>Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal data.</p>
      </section>
      
      <section>
        <h2>8. Limitation of Liability</h2>
        <p>StudyBuddy is provided "as is" without any warranties. We are not liable for any damages arising from your use of our service.</p>
      </section>
      
      <section>
        <h2>9. Changes to Terms</h2>
        <p>We may modify these Terms of Service at any time. Continued use of StudyBuddy after changes constitutes acceptance of the new terms.</p>
      </section>
      
      <section>
        <h2>10. Contact</h2>
        <p>If you have any questions about these Terms of Service, please contact us at studybuddy@lekan.ca.</p>
      </section>
    </div>
  );
};

export default TermsofService;
