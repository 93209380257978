import React, { useState } from 'react';
import { FaCode, FaCopy, FaCheck, FaTimes } from 'react-icons/fa';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../../Styles/CodeDisplayModal.css';

const CodeDisplayModal = ({ code, language }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleCopyCode = () => {
        navigator.clipboard.writeText(code);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    return (
        <>
            <div className='code-preview-box' onClick={openModal}>
                <FaCode className="code-icon" />
                <span className="code-preview-text">{`${language} implementation`}</span>
                <span className="code-preview-hint">Click to view code</span>
            </div>

            {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <div className="modal-header">
                            <span className="modal-title">{`${language} implementation`}</span>
                            <div className="modal-actions">
                                <button className="action-button copy-button" onClick={handleCopyCode}>
                                    {isCopied ? <FaCheck /> : <FaCopy />}
                                    <span className="button-text">{isCopied ? 'Copied!' : 'Copy'}</span>
                                </button>
                                <button className="action-button close" onClick={closeModal}>
                                    <FaTimes />
                                    <span className="button-text">Close</span>
                                </button>
                            </div>
                        </div>
                        <div className="code-container">
                            <SyntaxHighlighter
                                language={language.toLowerCase()}
                                style={vscDarkPlus}
                                showLineNumbers={true}
                                wrapLines={true}
                            >
                                {code}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CodeDisplayModal;
