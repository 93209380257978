/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import { login as apiLogin, getProfile, logout as apiLogout, fetchChats } from '../services/api';
import { useQueryClient, useQuery } from 'react-query';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [remainingChats, setRemainingChats] = useState(null);
  const [chats, setChats] = useState([]);

  const queryClient = useQueryClient();

  const checkUser = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const profileData = await getProfile();
        setUser(profileData); // Set the user data
        setSubscription(profileData.subscription);
        setRemainingChats(profileData.remainingChats);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error checking user:', error);
        logout();
      }
    } else {
      logout();
    }
    setIsLoading(false);
  }, []);

  // Add this new function to fetch chats
  const fetchUserChats = useCallback(async () => {
    if (user) {
      try {
        const chatsData = await fetchChats();
        const sortedChats = [...chatsData.chats].sort((a, b) => 
          new Date(b.last_modified || b.created_at).getTime() - new Date(a.last_modified || a.created_at).getTime()
        );
        setChats(sortedChats);
        
        // Update the query cache with sorted chats
        queryClient.setQueryData('chats', { ...chatsData, chats: sortedChats });
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    }
  }, [user, queryClient]);

  // Use React Query to manage chats data
  const { isLoading: isChatsLoading } = useQuery('chats', fetchUserChats, {
    enabled: !!user,
    staleTime: 5 * 60 * 1000, // 5 minutes
    onError: (error) => console.error('Error fetching chats data:', error)
  });

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  const login = async (email, password) => {
    try {
      const userData = await apiLogin(email, password);
      setUser(userData.user);
      console.log('User:', userData.user);

      const profileData = await getProfile();
      setUser(profileData); // Update user data with profile data
      setSubscription(profileData.subscription);
      setRemainingChats(profileData.remainingChats);
      
      setIsAuthenticated(true);

      return userData.data;
    } catch (error) {
      console.error('Login error in useAuth:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(error.response.data.message || 'Login failed. Please try again.');
      } else if (error.request) {
        // The request was made but no response was received
        throw new Error('No response from server. Please check your internet connection.');
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const logout = useCallback(() => {
    apiLogout();
    setUser(null);
    setIsAuthenticated(false);
    setSubscription(null);
    setRemainingChats(null);
    setChats([]);

    // Clear all React Query cache
    queryClient.clear();

    // Clear local storage items related to chats
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('chat_')) {
        localStorage.removeItem(key);
      }
    });
    
    // Remove the token from localStorage
    localStorage.removeItem('token');
  }, [queryClient]);

  const updateChatTitle = useCallback((chatId, newTitle) => {
    setChats(prevChats => {
      const updatedChats = prevChats.map(chat => 
        chat.id === chatId 
          ? { ...chat, title: newTitle, last_modified: new Date().toISOString() } 
          : chat
      );
      const sortedChats = updatedChats.sort((a, b) => 
        new Date(b.last_modified || b.created_at).getTime() - new Date(a.last_modified || a.created_at).getTime()
      );
      
      // Update the query cache
      queryClient.setQueryData('chats', oldData => ({ ...oldData, chats: sortedChats }));
      
      return sortedChats;
    });
  }, [queryClient]);

  return (
    <AuthContext.Provider value={{ 
      user, 
      isLoading, 
      isAuthenticated, 
      login, 
      logout, 
      checkUser, 
      globalLoading, 
      setGlobalLoading,
      subscription,
      remainingChats,
      chats,
      isChatsLoading,
      fetchUserChats,
      updateChatTitle
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);